<template>
	<div>
		<h2>{{ $t("monte.stock_semence.distant_for_stallion") }} {{ horse.horse_nom }}</h2>
		<CustomTable
			id_table="stock_stallion_distant"
			ref="table"
			:items="stock"
			:busy.sync="table_busy"
			primaryKey="uid"
	        :hrefsRoutes="config_table_hrefs"
		/>
	</div>
</template>

<script type="text/javascript">
import StockSemence from '@/mixins/StockSemence'

export default {
	name: 'StockStallionDistant',
	mixins: [StockSemence],
	props: ['horse'],
	data () {
		return {
			table_busy: false,
	        stock: [],
	        config_table_hrefs: {
				'to.tiers_rs': {
					routeUniqueName: 'tiersTableauBord',
					routeName: 'tiersTableauBord',
					params: {
						tiers_id: 'to.tiers_id'
					}
				},
			},
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.table_busy = true
			this.stock = await this.loadAllStockDistantDetailsByStallion(this.horse.horse_id)
			this.table_busy = false
		}
	},

	components: {
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
	}
}

</script>